import flatten from 'lodash/flatten';
import { createTrendlineMap } from '../../../utils/trendlines';
import { filterCollapsedGroups, fillDataset, fillEmptyDataset, getDataBoundaries, appendTrendlineDataset } from '../../internal/BarChart/utils';
import { createSeriesData } from './utils';
export function createData(_ref) {
  let {
    results,
    seriesOptions,
    collectionOptions = [],
    colorPallet,
    globalTrendline,
    legends: userLegends = []
  } = _ref;
  const trendlines = createTrendlineMap();
  let valueAxisTemplate = '';
  const collections = results.map((collection, collectionIndex) => {
    let colorIndexOffset = 0;
    return collection.map(selectionResultGroup => {
      const {
        selectionIndex
      } = selectionResultGroup;
      const options = seriesOptions[selectionIndex] || {}; // Use the first template found
      // as the template for the value axis

      if (options.template && !valueAxisTemplate) {
        valueAxisTemplate = options.template;
      } // Lookup any collection options


      const collectionOption = collectionOptions[collectionIndex] || {}; // Lookup color index offset for the group
      // to make sure the colors are unique

      const groupColorIndexOffset = colorIndexOffset; // Save the current color index offset

      colorIndexOffset += selectionResultGroup.data.length;
      return {
        group: selectionResultGroup,
        options,
        selectionIndex,
        colorIndexOffset: groupColorIndexOffset,
        collectionOption
      };
    }) // Filter collapsed
    .filter(_ref2 => {
      let {
        group,
        options
      } = _ref2;
      return filterCollapsedGroups(group, options);
    }) // Create series data
    .map(_ref3 => {
      let {
        group,
        options,
        collectionOption,
        selectionIndex,
        colorIndexOffset
      } = _ref3;
      return createSeriesData({
        group,
        options,
        collectionOption,
        selectionIndex,
        colorPallet,
        trendlines,
        globalTrendline,
        colorIndexOffset,
        isMultiSeries: true
      });
    });
  }); // Labels are shared across all datasets

  const labels = []; // List of unique group names

  const legends = []; // The number of datasets should match
  // the maximum number of series in any group.
  // If a group has less series than the maximum,
  // fill the dataset with empty data.

  const datasets = [];
  collections.forEach((groupSelections, groupIdx) => {
    const group = flatten(groupSelections);
    const collectionOption = collectionOptions[groupIdx] || {}; // Append the label, empty by default

    labels.push(collectionOption.label || ''); // Append the legend for the group

    legends.push(userLegends[groupIdx] || `Legend ${groupIdx + 1}`);
    group.forEach((seriesData, datasetIdx) => {
      if (!datasets[datasetIdx]) {
        datasets[datasetIdx] = {
          data: [],
          backgroundColor: [],
          templates: [],
          titles: [],
          selections: [],
          selectionIndexes: [],
          datalabels: []
        };
      } // fill the dataset with empty data if needed
      // to match the max amount of series in the group


      if (datasetIdx > 0 && datasets[datasetIdx].data.length < groupIdx) {
        const emptyDatasetsCount = groupIdx - datasets[datasetIdx].data.length;

        for (let i = 0; i < emptyDatasetsCount; i++) {
          fillEmptyDataset(datasets[datasetIdx]);
        }
      }

      fillDataset(seriesData, datasets[datasetIdx]);
    });
  }); // Calculate min/max of all datasets

  const dataBoundaries = getDataBoundaries(datasets); // Append optional trendline datasets

  appendTrendlineDataset(datasets, trendlines, dataBoundaries);
  return {
    legendsCount: legends.length,
    legends: legends,
    labels: labels,
    datasets: datasets,
    dataBoundaries: dataBoundaries,
    valueAxisTemplate
  };
}