import { useState, useEffect } from 'react';
import logger from '@/utils/logger';
const PREFIX = 'common: components: reporting: Layout: useConfig:';
const DEFAULTS = {
  reportingWidgetId: '',
  spreadsheet: '',
  config: {
    heading: '',
    layout: 'standard',
    ctaPrompt: '',
    ctaButton: '',
    ctaButtonColor: '',
    ctaDestination: '',
    reportQuery: '',
    reportCtaThreshold: '',
    ctaPromptInputs: '',
    showAccreditation: true
  },
  sections: {}
}; // Ensures all requred attributes exist

export default function useConfig(selector, attrs) {
  let updatedAt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const {
    0: widgetId,
    1: setWidgetId
  } = useState(attrs.widgetId);
  const {
    0: reportingWidgetId,
    1: setReportingWidgetId
  } = useState(attrs.reportingWidgetId || DEFAULTS.reportingWidgetId);
  const {
    0: elementId,
    1: setElementId
  } = useState(attrs.elementId || '');
  const {
    0: config,
    1: setConfig
  } = useState(attrs.config || DEFAULTS.config);
  const {
    0: layout,
    1: setLayout
  } = useState(attrs.layout || DEFAULTS.config.layout);
  const {
    0: sections,
    1: setSections
  } = useState(attrs.sections || DEFAULTS.sections);
  const {
    0: spreadsheet,
    1: setSpreadsheet
  } = useState(attrs.spreadsheet || DEFAULTS.spreadsheet);
  const {
    0: workbook,
    1: setWorkbook
  } = useState(attrs.workbook || undefined);
  const {
    0: workbookMeta,
    1: setWorkbookMeta
  } = useState(attrs.workbookMeta || undefined);
  const hasInlineConfig = Boolean(attrs.config) && Boolean(attrs.sections);

  if (!hasInlineConfig && !selector) {
    throw Error(`${PREFIX} requires an inline configuration or a config script selector`);
  } // Configure component from
  // selector's script config/JSON


  useEffect(() => {
    if (!selector || typeof document === 'undefined') return; // Lookup configuration script

    const configScript = document.querySelector(selector);
    const unparsedConfig = configScript ? configScript.innerHTML : JSON.stringify({});

    if (!configScript) {
      logger.warn(`${PREFIX} config script for selector: "${selector}" not found`);
    }

    try {
      const parsedConfig = JSON.parse(unparsedConfig);
      syncSettings(parsedConfig);
    } catch (err) {
      logger.error(`${PREFIX} failed to parse selector's script configuration: ${err}`);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [selector]); // Sync inline configuration

  useEffect(() => {
    if (Boolean(selector) && hasInlineConfig) return;
    syncSettings(attrs);
  }, [selector, hasInlineConfig, attrs, updatedAt]);

  function syncSettings(source) {
    if (source.reportingWidgetId) setReportingWidgetId(source.reportingWidgetId); // eslint-disable-line

    if (source.widgetId) setWidgetId(source.widgetId); // eslint-disable-line

    if (source.elementId) setElementId(source.elementId); // eslint-disable-line

    if (source.config) setConfig(source.config); // eslint-disable-line

    if (source.layout) setLayout(source.layout); // eslint-disable-line

    if (source.sections) setSections(source.sections); // eslint-disable-line

    if (source.spreadsheet) setSpreadsheet(source.spreadsheet); // eslint-disable-line

    if (source.workbook) setWorkbook(source.workbook); // eslint-disable-line

    if (source.workbookMeta) setWorkbookMeta(source.workbookMeta); // eslint-disable
  }

  return {
    widgetId,
    reportingWidgetId,
    elementId,
    config,
    layout,
    sections,
    spreadsheet,
    workbook,
    workbookMeta
  };
}