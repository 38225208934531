import { useMemo } from 'react';
import { createData as createPairedDatasets } from './pairsToResults';
import { createData as createStackedDatasets } from './stackedToResults';
import { createData as createCategorizedDatasets } from './categoriesToResults';
export default function useData(_ref) {
  let {
    results,
    seriesOptions,
    collectionOptions = [],
    columnLabels,
    legends,
    colorPallet,
    selectionsStrategy,
    hasLegend = true
  } = _ref;
  const chartData = useMemo(() => {
    if (!results || !results.length) {
      return {
        labels: [],
        datasets: []
      };
    } // Lookup the createData function based on the selectionsStrategy


    const createDataFunctions = {
      stacked: createStackedDatasets,
      categories: createCategorizedDatasets,
      default: createPairedDatasets
    };
    const createData = createDataFunctions[selectionsStrategy || 'default'];
    return createData({
      results,
      seriesOptions,
      colorPallet,
      collectionOptions,
      columnLabels,
      legends,
      hasLegend
    });
  }, [columnLabels, legends, results, seriesOptions, colorPallet, collectionOptions, selectionsStrategy, hasLegend]);
  return {
    chartData
  };
}