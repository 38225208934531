import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import design from '@/config/design';
import templateEngine from '@/utils/templateEngine';
import { isDark } from '@/utils/colors';
import { createTrendlineDataset } from '@/components/widget/utils/trendlines'; // Check is grouped strategy

export function checkIsGrouped(selectionsStrategy, results) {
  return ['groups', 'stacked'].includes(selectionsStrategy) || results.length > 1;
}
export function filterCollapsedGroups(group, options) {
  // Filter out collapsed group
  // when all its' values are not truthy
  if (options.collapsible) {
    return group.data.some(item => Boolean(item.value));
  }

  return true;
} // Fill dataset with series data

export function fillDataset(data, dataset, labels) {
  const {
    value,
    selection,
    selectionIndex,
    name,
    template,
    color
  } = data;
  if (labels) labels.push(name);

  if (data.datalabels) {
    dataset.datalabels.push(data.datalabels);
  }

  dataset.data.push(value);
  dataset.backgroundColor.push(color);
  dataset.templates.push(template);
  dataset.titles.push(name);
  dataset.selections.push(selection);
  dataset.selectionIndexes.push(selectionIndex);
} // Fill dataset with empty data

export function fillEmptyDataset(dataset) {
  if (dataset.datalabels) {
    dataset.datalabels.push(getDefaultDataLabelOptions());
  }

  dataset.data.push(0);
  dataset.templates.push('');
  dataset.titles.push('');
  dataset.selections.push('');
  dataset.selectionIndexes.push(-1);
} // Append trendline data to datasets

export function appendTrendlineDataset(datasets, trendlines, dataBoundaries) {
  for (const [trendlineOptions, trendlineData] of trendlines) {
    if (trendlineData.length < 2) continue;
    const trendline = createTrendlineDataset(trendlineOptions, trendlineData, dataBoundaries.min, dataBoundaries.max);

    if (trendline && trendline.display) {
      datasets.push(_objectSpread(_objectSpread({}, trendline), {}, {
        data: trendline.data.map(item => item.x)
      }));
    }
  }
} // Calculate min/max of all datasets

export function getDataBoundaries(datasets) {
  let stacked = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (stacked) {
    // Calculate min/max for stacked datasets
    // by summing up all values in each column
    const columnCount = datasets.reduce((acc, dataset) => {
      return Math.max(acc, dataset.data.length);
    }, 0);
    let min = 0;
    let max = 0;

    for (let i = 0; i < columnCount; i++) {
      // Sum up all values in the column
      const columnSum = datasets.reduce((sum, dataset) => sum + (dataset.data[i] || 0), 0);
      min = Math.min(min, columnSum);
      max = Math.max(max, columnSum);
    }

    return {
      min,
      max
    };
  }

  return datasets.reduce((acc, dataset) => {
    acc.min = Math.min(acc.min, ...dataset.data);
    acc.max = Math.max(acc.max, ...dataset.data);
    return acc;
  }, {
    min: Infinity,
    max: -Infinity
  });
} // Flattens the data from the datasets into a string

export function dataToString(datasets) {
  return joinNewLine(datasets.map(dataset => joinNewLine(dataset.data.map((item, idx) => `${dataset.titles[idx]} => ${item}`))));
} // Converts the data from the datasets into a string array

export function dataToStringArr(datasets) {
  return datasets.map(dataset => joinNewLine(dataset.data.map((item, idx) => `${dataset.titles[idx]} => ${item}`)));
}
export function joinNewLine(arr) {
  return arr.join('\n');
} // Get data label color contrast to background color

export function getDataLabelColor(backgroundColor) {
  return isDark(backgroundColor) ? design.colors.white : design.colors.gray100;
}
export function getDefaultDataLabelOptions() {
  return {
    display: false,
    color: design.colors.gray100,
    align: 'center',
    anchor: 'end',
    rotation: 0,
    clamp: true,
    template: '0'
  };
} // Get data label options for each data point
// based on series and collection options

export function getDataLabelOptions(params) {
  var _options$dataLabels, _collectionOption$dat, _options$dataLabels2, _collectionOption$dat2, _options$dataLabels3, _collectionOption$dat3, _options$dataLabels4, _collectionOption$dat4, _options$dataLabels5, _collectionOption$dat5, _options$dataLabels6;

  const {
    value,
    backgroundColor,
    options,
    collectionOption = {}
  } = params;
  const defaultOptions = getDefaultDataLabelOptions(); // Get contrast color for data labels

  const dataLabelColor = getDataLabelColor(backgroundColor); // Data label options

  const displayDataLabels = ((_options$dataLabels = options.dataLabels) === null || _options$dataLabels === void 0 ? void 0 : _options$dataLabels.display) ?? ((_collectionOption$dat = collectionOption.dataLabels) === null || _collectionOption$dat === void 0 ? void 0 : _collectionOption$dat.display) ?? defaultOptions.display;
  const alignment = ((_options$dataLabels2 = options.dataLabels) === null || _options$dataLabels2 === void 0 ? void 0 : _options$dataLabels2.alignment) ?? ((_collectionOption$dat2 = collectionOption.dataLabels) === null || _collectionOption$dat2 === void 0 ? void 0 : _collectionOption$dat2.alignment) ?? defaultOptions.align;
  const anchoring = ((_options$dataLabels3 = options.dataLabels) === null || _options$dataLabels3 === void 0 ? void 0 : _options$dataLabels3.anchoring) ?? ((_collectionOption$dat3 = collectionOption.dataLabels) === null || _collectionOption$dat3 === void 0 ? void 0 : _collectionOption$dat3.anchoring) ?? defaultOptions.anchor;
  const rotation = ((_options$dataLabels4 = options.dataLabels) === null || _options$dataLabels4 === void 0 ? void 0 : _options$dataLabels4.rotation) ?? ((_collectionOption$dat4 = collectionOption.dataLabels) === null || _collectionOption$dat4 === void 0 ? void 0 : _collectionOption$dat4.rotation) ?? defaultOptions.rotation;
  const clamping = ((_options$dataLabels5 = options.dataLabels) === null || _options$dataLabels5 === void 0 ? void 0 : _options$dataLabels5.clamping) ?? ((_collectionOption$dat5 = collectionOption.dataLabels) === null || _collectionOption$dat5 === void 0 ? void 0 : _collectionOption$dat5.clamping) ?? defaultOptions.clamp; // Provide default template

  const dataLabelTemplate = ((_options$dataLabels6 = options.dataLabels) === null || _options$dataLabels6 === void 0 ? void 0 : _options$dataLabels6.template) || `{{value}}`;
  return {
    display: displayDataLabels,
    color: dataLabelColor,
    align: alignment,
    anchor: anchoring,
    rotation: rotation,
    clamp: clamping,
    template: templateEngine(dataLabelTemplate, {
      value: value
    })
  };
} // Extract data label property from context
// with default value

export function extractDataLabelProperty(context, property, defaultValue) {
  var _context$dataset$data;

  const dataLabel = (_context$dataset$data = context.dataset.datalabels) === null || _context$dataset$data === void 0 ? void 0 : _context$dataset$data[context.dataIndex];
  if (!dataLabel) return defaultValue;
  return dataLabel[property] ?? defaultValue;
}