import flatten from 'lodash/flatten';
import { createTrendlineMap } from '../../../utils/trendlines';
import { filterCollapsedGroups, fillDataset, getDataBoundaries, appendTrendlineDataset } from '../../internal/BarChart/utils';
import { createSeriesData } from './utils';
export function createData(_ref) {
  let {
    results,
    seriesOptions,
    colorPallet,
    globalTrendline,
    legends: userLegends = []
  } = _ref;
  const trendlines = createTrendlineMap();
  let valueAxisTemplate = '';
  const pairs = flatten(results).map(selectionResultGroup => {
    const {
      selectionIndex
    } = selectionResultGroup;
    const options = seriesOptions[selectionIndex] || {}; // Use the first template found
    // as the template for the value axis

    if (options.template && !valueAxisTemplate) {
      valueAxisTemplate = options.template;
    }

    return {
      group: selectionResultGroup,
      options,
      selectionIndex
    };
  }) // Filter collapsed
  .filter(_ref2 => {
    let {
      group,
      options
    } = _ref2;
    return filterCollapsedGroups(group, options);
  }) // Create series data
  .map(_ref3 => {
    let {
      group,
      options,
      selectionIndex
    } = _ref3;
    return createSeriesData({
      group,
      options,
      selectionIndex,
      colorPallet,
      trendlines,
      globalTrendline,
      isMultiSeries: group.data.length > 1
    });
  }); // Create default dataset

  const labels = [];
  const dataset = {
    data: [],
    backgroundColor: [],
    templates: [],
    titles: [],
    selections: [],
    selectionIndexes: [],
    datalabels: []
  }; // List of unique series names

  const legends = []; // Flatten selection groups into single
  // list for each collection of selections
  // and fill the dataset

  flatten(pairs).forEach((seriesData, seriesIdx) => {
    legends.push(userLegends[seriesIdx] || seriesData.name || `Legend ${seriesIdx + 1}`);
    fillDataset(seriesData, dataset, labels);
  });
  const datasets = [dataset]; // Calculate min/max

  const dataBoundaries = getDataBoundaries(datasets); // Append optional trendline dataset

  appendTrendlineDataset(datasets, trendlines, dataBoundaries);
  return {
    legendsCount: legends.length,
    legends: legends,
    labels: labels,
    datasets: datasets,
    dataBoundaries: dataBoundaries,
    valueAxisTemplate
  };
}