import design, { _remCalc } from '@/config/design';
import { // FilterBase,
FilterValueType } from '@/utils/widget/createSelections/interfaces';
export default {
  styles: {
    height: '100%',
    minChartHeight: _remCalc(191),
    paddingTop: design.globals.padding,
    paddingRight: design.globals.padding,
    paddingBottom: design.globals.padding,
    paddingLeft: design.globals.padding
  }
};
export const selectionConfig = {
  valueType: FilterValueType.Numbers
};