import envConfig from '@/config/environment';
import cdnConfig from '@/config/cdn'; // const PREFIX = 'services: cdn:';
// Get the absolute url to the widget configs'
// JSON, within Firebase Hosting Domain, which
// is hopefully cached

export function getCDNWidgetConfigUrl(widgetId) {
  let baseUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : envConfig.baseUrl;
  return `${baseUrl}/${cdnConfig.widgetConfigsPath}/${widgetId}.json`;
}
export default {
  getCDNWidgetConfigUrl
};