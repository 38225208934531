import templateEngine from '@/utils/templateEngine';
import { getColorAtPalletIndex } from '@/utils/colors';
import { getDataLabelOptions } from '../../internal/BarChart/utils'; // Create series data from group

export function createSeriesData(params) {
  var _group$data$find;

  const {
    collectionIdx,
    selectionIndex,
    group,
    options,
    collectionOption = {},
    colorPallet = [],
    colorIndex,
    isMultiSeries = false
  } = params;
  const userLabels = options.labels || {};
  const collapsible = Boolean(options.collapsible);
  const hideLegend = typeof options.omitFromLegend === 'boolean' ? options.omitFromLegend : false; // Provide default template

  const template = options.template || `{{value}}`; // Lookup default group label

  const groupLabel = (_group$data$find = group.data.find(item => Boolean(item.label))) === null || _group$data$find === void 0 ? void 0 : _group$data$find.label; // Convert each item in group
  // into a renderable item for
  // the visualization

  return group.data // Filter out individual falsey items
  .filter(item => collapsible ? Boolean(item.value) : true).map((item, itemIdx) => {
    let value = parseFloat(`${item.value}`);
    value = isNaN(value) ? 0 : value;
    const customLabel = userLabels[item.labelCell || ''] || options.label || ''; // For multi-series, the color should be based on the item index
    // to ensure each series has a different color.

    const defaultColorIndex = isMultiSeries ? itemIdx : selectionIndex;
    const color = options.color || collectionOption.color || getColorAtPalletIndex( // Skip over null or undefined, until defined
    // Prioritize by collection, then color, then selection index
    collectionIdx ?? colorIndex ?? defaultColorIndex, colorPallet);
    const seriesData = {
      value,
      selection: group.selections,
      selectionIndex,
      hideLegend,
      name: customLabel || item.label || groupLabel || group.selections,
      template: templateEngine(template, {
        value: item.value
      }),
      color: color,
      datalabels: getDataLabelOptions({
        value,
        backgroundColor: color,
        options,
        collectionOption
      })
    };
    return seriesData;
  });
}