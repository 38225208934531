import { chartColorPallet as DEFAULT_COLORS } from '@/config/colorPallets/universalDesignSystem';
// Lookup a default color at the
// specified index of a color pallet
// and loop over them in succession
export function getColorAtPalletIndex(index) {
  let colorPallet = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_COLORS;
  const colorsLen = colorPallet.length;
  return colorPallet[index % colorsLen];
} // Encode an array of colors into a string

export function encodeColors(colors) {
  return (Array.isArray(colors) ? colors : [colors || '']).join('|');
} // Decode a string of encoded colors into a list

export function decodeColors(colors) {
  return `${colors || ''}`.trim().split('|');
} // Convert an rgb color instance to a
// string with optional alpha

export function rgbToString(rgb) {
  const hasAlpha = rgb.a !== undefined;
  return `${hasAlpha ? 'rgba' : 'rgb'}(${rgb.r},${rgb.g},${rgb.b}${hasAlpha ? ',' : ''}${hasAlpha ? rgb.a : ''})`;
} // Convert HEX and HSL values to RGBA
// Source: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb#answer-74662179
// Example RGB: rgb(0, 77, 229)

export function toRGB(color) {
  const {
    style
  } = new Option();
  style.color = color;
  return style.color;
} // Get the brightness of a color
// http://www.w3.org/TR/AERT#color-contrast

export function getBrightness(color) {
  const rgb = toRGB(color); // Extract the RGB values
  // Coverts rgb(0, 77, 229) -> [0, 77, 229]

  const values = rgb.match(/\d+/g);
  if (!values) return -1;
  const r = +values[0];
  const g = +values[1];
  const b = +values[2];
  return (r * 299 + g * 587 + b * 114) / 1000;
} // Determine if a color is dark

export function isDark(color) {
  return getBrightness(color) < 128;
}