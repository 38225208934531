import design from '@/config/design';
import { FilterBase, FilterValueType } from '@/utils/widget/createSelections/interfaces';
export default {
  styles: {
    height: '100%',
    borderTopWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '0',
    borderLeftWidth: '0',
    paddingRight: design.globals.padding,
    paddingLeft: design.globals.padding,
    paddingTop: design.globals.padding,
    paddingBottom: design.globals.padding
  }
};
export const selectionConfig = {
  base: FilterBase.Pairs,
  valueType: FilterValueType.Numbers,
  seriesMinimumLength: 1
};