import flattenDeep from 'lodash/flattenDeep';
import flatten from 'lodash/flatten';
import { fillDataset, fillEmptyDataset, filterCollapsedGroups, getDataBoundaries } from '../../internal/BarChart/utils';
import { createSeriesData } from './utils';
export function createData(_ref) {
  let {
    results,
    seriesOptions,
    colorPallet,
    columnLabels = [],
    legends = []
  } = _ref;
  // Group results by selection index
  const resultsBySelectionIdx = flattenDeep(results).reduce((acc, selectionResultGroup) => {
    const {
      selectionIndex
    } = selectionResultGroup;

    if (!acc[selectionIndex]) {
      acc[selectionIndex] = [];
    }

    acc[selectionIndex].push(selectionResultGroup);
    return acc;
  }, {});
  let valueAxisTemplate = '';
  const collections = Object.values(resultsBySelectionIdx).map(selection => {
    return selection.map(selectionResultGroup => {
      const {
        selectionIndex
      } = selectionResultGroup;
      const options = seriesOptions[selectionIndex] || {}; // Use the first template found
      // as the template for the value axis

      if (options.template && !valueAxisTemplate) {
        valueAxisTemplate = options.template;
      }

      return {
        group: selectionResultGroup,
        options,
        selectionIndex
      };
    }) // Filter collapsed
    .filter(_ref2 => {
      let {
        group,
        options
      } = _ref2;
      return filterCollapsedGroups(group, options);
    }) // Create series data
    .map(_ref3 => {
      let {
        group,
        options,
        selectionIndex
      } = _ref3;
      return createSeriesData({
        group,
        options,
        selectionIndex,
        colorPallet,
        isMultiSeries: true
      });
    });
  }); // Labels are shared across all datasets

  const labels = [];
  let legendsCount = 0;
  const datasets = [];
  collections.forEach((groupSelections, groupIdx) => {
    const group = flatten(groupSelections);
    labels.push(columnLabels[groupIdx] || '');
    group.forEach((seriesData, datasetIdx) => {
      if (!datasets[datasetIdx]) {
        // Increment legends count
        legendsCount += 1; // Create a new dataset

        datasets[datasetIdx] = {
          data: [],
          backgroundColor: [],
          templates: [],
          titles: [],
          selections: [],
          selectionIndexes: [],
          datalabels: [],
          label: legends[datasetIdx] || `Legend ${datasetIdx + 1}`
        };
      } // fill the dataset with empty data if needed
      // to match the max amount of series in the group


      if (datasetIdx > 0 && datasets[datasetIdx].data.length < groupIdx) {
        const emptyDatasetsCount = groupIdx - datasets[datasetIdx].data.length;

        for (let i = 0; i < emptyDatasetsCount; i++) {
          fillEmptyDataset(datasets[datasetIdx]);
        }
      }

      fillDataset(seriesData, datasets[datasetIdx]);
    });
  }); // Calculate min/max of all datasets

  const dataBoundaries = getDataBoundaries(datasets, true);
  return {
    legendsCount,
    labels: labels,
    datasets: datasets,
    dataBoundaries: dataBoundaries,
    valueAxisTemplate: valueAxisTemplate
  };
}