import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import teams from '@/services/firestore/team';
import workbooks from '@/services/firestore/workbooks';
import cdn from '@/services/cdn';
import account from '@/services/auth/account';
import generalConfig from '@/config/general';
import createApiError from '@/utils/api/createError';
import { createWrappedError } from '@/utils/errors';
import fetchJson from '@/common/utils/fetchJson';
const PREFIX = 'services: api: widgets:';
const API_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_DOMAIN;
const generateDeleteError = createApiError(`${PREFIX} deleteRecord:`);
const generateUpdateError = createApiError(`${PREFIX} updateRecord:`);
const generateCreateError = createApiError(`${PREFIX} createRecord:`);
const generateCreateClonedError = createApiError(`${PREFIX} createClonedRecord:`);
const generateUpdateReportingWidgetError = createApiError(`${PREFIX} updateReportingWidget:`);
const generateGetWidgetStatsError = createApiError(`${PREFIX} getWidgetStats:`); // DELETE request to destroy widget

const deleteRequest = (authToken, widgetId) => fetch(`${API_DOMAIN}/api/v0/widgets/${widgetId}`, {
  method: 'DELETE',
  headers: {
    Authorization: `FB-JWT ${authToken}`,
    'Content-Type': 'application/json'
  }
});

export const deleteRecord = async widgetId => {
  let authToken = '';

  try {
    authToken = await account.getIdToken();
  } catch (err) {
    throw createWrappedError(`${PREFIX} deleteRecord: auth token could not be recovered`, err);
  }

  let response;

  try {
    response = await deleteRequest(authToken, widgetId);
  } catch (err) {
    throw createWrappedError(`${PREFIX} deleteRecord: request failed`, err);
  }

  let responseJson = {};

  if (response.status !== 204) {
    try {
      responseJson = await response.json();
    } catch (err) {
      throw createWrappedError(`${PREFIX} deleteRecord: failed to parse JSON`, err);
    }
  } // Throw unsuccessful request API error


  const apiError = generateDeleteError(response.status, responseJson.errors);

  if (apiError) {
    throw apiError;
  }
}; // PUT request to update widget
// optional to publish widget config
// to published embeds

const putRequest = (authToken, widgetId, body, publish) => fetch(`${API_DOMAIN}/api/v0/widgets/${widgetId}${!publish ? '?publish=false' : ''}`, {
  method: 'PUT',
  headers: {
    Authorization: `FB-JWT ${authToken}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(_objectSpread({}, body))
});

export const updateRecord = async (widgetId, updates, publish) => {
  let authToken = '';

  try {
    authToken = await account.getIdToken();
  } catch (err) {
    throw createWrappedError(`${PREFIX} updateRecord: auth token could not be recovered`, err);
  }

  let response;

  try {
    response = await putRequest(authToken, widgetId, updates, publish);
  } catch (err) {
    throw createWrappedError(`${PREFIX} updateRecord: PUT request failed`, err);
  }

  let responseJson = {};

  if (response.status !== 204) {
    try {
      responseJson = await response.json();
    } catch (err) {
      throw createWrappedError(`${PREFIX} updateRecord: failed to parse JSON`, err);
    }
  } // Throw unsuccessful request API error


  const apiError = generateUpdateError(response.status, responseJson.errors);

  if (apiError) {
    throw apiError;
  }

  const isExpected = responseJson.data && responseJson.data.attributes;

  if (!isExpected) {
    throw Error(`${PREFIX} updateRecord: unexpected response payload`);
  }

  return _objectSpread(_objectSpread({}, responseJson.data.attributes), {}, {
    id: responseJson.data.id
  });
}; // Sync with /functions/src/widgets/interfaces.ts

// POST request to create a new widget from a payload
const postRequest = (authToken, body) => fetch(`${API_DOMAIN}/api/v0/widgets`, {
  method: 'POST',
  headers: {
    Authorization: `FB-JWT ${authToken}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(_objectSpread({}, body))
});

export const createRecord = async payload => {
  var _responseJson$data, _responseJson$data$re, _responseJson$data$re2, _responseJson$data$re3, _responseJson$data2, _responseJson$data2$r, _responseJson$data2$r2, _responseJson$data2$r3;

  let authToken = '';

  try {
    authToken = await account.getIdToken();
  } catch (err) {
    throw createWrappedError(`${PREFIX} createRecord: auth token could not be recovered`, err);
  }

  let response;

  try {
    response = await postRequest(authToken, payload);
  } catch (err) {
    throw createWrappedError(`${PREFIX} createRecord: POST request failed`, err);
  }

  let responseJson = {};

  if (response.status !== 204) {
    try {
      responseJson = await response.json();
    } catch (err) {
      throw createWrappedError(`${PREFIX} createRecord: failed to parse JSON`, err);
    }
  } // Throw unsuccessful request API error


  const apiError = generateCreateError(response.status, responseJson.errors);

  if (apiError) {
    throw apiError;
  }

  const isExpected = responseJson.data && responseJson.data.attributes;

  if (!isExpected) {
    throw Error(`${PREFIX} createRecord: unexpected response payload`);
  }

  const result = _objectSpread(_objectSpread({}, responseJson.data.attributes), {}, {
    id: responseJson.data.id
  });

  if ((_responseJson$data = responseJson.data) !== null && _responseJson$data !== void 0 && (_responseJson$data$re = _responseJson$data.relationships) !== null && _responseJson$data$re !== void 0 && (_responseJson$data$re2 = _responseJson$data$re.team) !== null && _responseJson$data$re2 !== void 0 && (_responseJson$data$re3 = _responseJson$data$re2.data) !== null && _responseJson$data$re3 !== void 0 && _responseJson$data$re3.id) {
    result.team = teams.createDocRef(responseJson.data.relationships.team.data.id);
  }

  if ((_responseJson$data2 = responseJson.data) !== null && _responseJson$data2 !== void 0 && (_responseJson$data2$r = _responseJson$data2.relationships) !== null && _responseJson$data2$r !== void 0 && (_responseJson$data2$r2 = _responseJson$data2$r.workbook) !== null && _responseJson$data2$r2 !== void 0 && (_responseJson$data2$r3 = _responseJson$data2$r2.data) !== null && _responseJson$data2$r3 !== void 0 && _responseJson$data2$r3.id) {
    result.workbook = workbooks.createDocRef(responseJson.data.relationships.workbook.data.id);
  }

  return result;
}; // POST request to create widget from another widget

const postCloneRequest = (authToken, cloneWidgetId, projectId) => fetch(`${API_DOMAIN}/api/v0/widgets/clone/${cloneWidgetId}${projectId ? `?project=${projectId}` : ''}`, {
  method: 'POST',
  headers: {
    Authorization: `FB-JWT ${authToken}`,
    'Content-Type': 'application/json'
  }
});

export const createClonedRecord = async (cloneWidgetId, projectId) => {
  let authToken = '';

  try {
    authToken = await account.getIdToken();
  } catch (err) {
    throw createWrappedError(`${PREFIX} createClonedRecord: auth token could not be recovered`, err);
  }

  let response;

  try {
    response = await postCloneRequest(authToken, cloneWidgetId, projectId);
  } catch (err) {
    throw createWrappedError(`${PREFIX} createClonedRecord: POST request failed`, err);
  }

  let responseJson = {};

  if (response.status !== 204) {
    try {
      responseJson = await response.json();
    } catch (err) {
      throw createWrappedError(`${PREFIX} createClonedRecord: failed to parse JSON`, err);
    }
  } // Throw unsuccessful request API error


  const apiError = generateCreateClonedError(response.status, responseJson.errors);

  if (apiError) {
    throw apiError;
  }

  const isExpected = responseJson.data && responseJson.data.attributes;

  if (!isExpected) {
    throw Error(`${PREFIX} createClonedRecord: unexpected response payload`);
  }

  return _objectSpread(_objectSpread({}, responseJson.data.attributes), {}, {
    id: responseJson.data.id
  });
}; // PUT request to update widget's
// reporting widget

const putReportingWidget = (authToken, widgetId, reportingWidgetId) => fetch(`${API_DOMAIN}/api/v0/widgets/${widgetId}/reporting-widget/${reportingWidgetId}`, {
  method: 'PUT',
  headers: {
    Authorization: `FB-JWT ${authToken}`
  }
});

export const updateReportingWidget = async (widgetId, reportingWidgetId) => {
  let authToken = '';

  try {
    authToken = await account.getIdToken();
  } catch (err) {
    throw createWrappedError(`${PREFIX} updateReportingWidget: auth token could not be recovered`, err);
  }

  let response;

  try {
    response = await putReportingWidget(authToken, widgetId, reportingWidgetId);
  } catch (err) {
    throw createWrappedError(`${PREFIX} updateReportingWidget: PUT request failed`, err);
  }

  const contentType = response.headers.get('Content-Type') || '';
  const isJsonResponse = contentType.search('json') > -1;
  let responseJson = {};

  if (isJsonResponse) {
    if (response.status !== 204) {
      try {
        responseJson = await response.json();
      } catch (err) {
        throw Error(`${PREFIX} updateReportingWidget: failed to parse JSON: ${err}`);
      }
    }
  } // Throw unsuccessful request API error


  const apiError = generateUpdateReportingWidgetError(response.status, responseJson ? responseJson.errors : []);

  if (apiError) {
    throw apiError;
  }
}; // GET widgets's stats

const getWidgetStatsRequest = (widgetId, authToken) => {
  return fetch(`${API_DOMAIN}/api/v0/widgets/${widgetId}/menus.json`, {
    method: 'GET',
    headers: {
      ['Accept']: '*/*',
      ['Accept-Encoding']: 'gzip, deflate, br, zstd',
      ['Accept-Language']: 'en-US,en;q=0.9',
      // Source: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-Modified-Since
      ['If-Modified-Since']: new Date().toUTCString(),
      // Date in format: 'Thu, 01 Jan 1970 00:00:00 GMT'
      ['If-None-Match']: `${authToken}`
    }
  });
}; // Publish Widget Statistics to API


const getWidgetStats = async (widgetId, params) => {
  let authToken = '';

  try {
    authToken = getWidgetStatsAuthToken(widgetId, params);
  } catch (err) {
    throw Error(`${PREFIX} getWidgetStats: auth token could not be recovered: ${err}`);
  }

  let response;

  try {
    response = await getWidgetStatsRequest(widgetId, authToken);
  } catch (err) {
    throw Error(`${PREFIX} getWidgetStats: GET request failed: ${err}`);
  }

  let responseJson = {};

  try {
    responseJson = await response.json();
  } catch (err) {
    throw Error(`${PREFIX} getWidgetStats: failed to parse JSON: ${err}`);
  } // Throw unsuccessful request API error


  const apiError = generateGetWidgetStatsError(response.status, responseJson.errors);

  if (apiError) {
    throw apiError;
  }
}; // Create an auth token for a widget stats request
// consisting of the the 1st and last 4 characters
// of the widgetId and query params of the stat to track


const getWidgetStatsAuthToken = (widgetId, params) => {
  const firstFour = widgetId.slice(0, 4);
  const lastFour = widgetId.slice(-4);
  const authToken = `Z${firstFour}${lastFour}${generalConfig.productNameSpace}?${params.map(p => `${p}=1`).join('&')}`;
  return encodeStatsAuthToken(authToken);
}; // Encode the token using ROT13
// Definition: https://en.wikipedia.org/wiki/ROT13


const encodeStatsAuthToken = authToken => {
  return authToken.replace(/[a-zA-Z]/g, c => {
    return String.fromCharCode(c.charCodeAt(0) + (c.toLowerCase() <= 'm' ? 13 : -13));
  });
}; // GET widget's config


const getWidgetConfig = async widgetId => {
  if (!widgetId) {
    throw Error(`${PREFIX} getWidgetConfig: called without a valid widget ID`);
  }

  const widgetConfigUrl = cdn.getCDNWidgetConfigUrl(widgetId);
  return await fetchJson(widgetConfigUrl);
}; // GET reporting widget's config


const getReportingWidgetConfig = async widget => {
  if (!widget) {
    throw Error(`${PREFIX} getReportingWidgetConfig: called without a valid widget`);
  }

  if (widget && !widget.reportingWidget) {
    return _objectSpread(_objectSpread({}, widget), {}, {
      id: widget.id
    });
  }

  if (!widget.reportingWidget || !widget.reportingWidget.id) {
    throw Error(`${PREFIX} getReportingWidgetConfig: malformed subscriber widget has no reporting widget ID`);
  } // Reporter


  const widgetConfigUrl = cdn.getCDNWidgetConfigUrl(widget.reportingWidget.id);

  try {
    const widgetConfig = await fetchJson(widgetConfigUrl);
    return _objectSpread(_objectSpread({}, widgetConfig), {}, {
      id: widget.id
    });
  } catch (err) {
    throw Error(`${PREFIX} getReportingWidgetConfig: failed to download widget config JSON at: "${widgetConfigUrl}": ${err}`);
  }
};

export default {
  deleteRecord,
  updateRecord,
  createRecord,
  createClonedRecord,
  updateReportingWidget,
  getWidgetStats,
  getWidgetConfig,
  getReportingWidgetConfig
};